import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "0f91b376",
  "date": "2021-12-16"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`0f91b376`}</h1>
    <h2>{`Summary`}</h2>
    <p>{`This disc was mailed out as part of the MiniDisc Mix, to get people started.
It was suggested people reformat the disc for their own mix, to encourage
others getting their mixes distributed. The music selections were things RJ
liked, and some light shitposting.`}</p>
    <h2>{`Tracks`}</h2>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.discogs.com/release/107111-UNKLESounds-Do-Androids-Dream-Of-Electric-Beats"
        }}>{`Acapella (Biz Markie)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.discogs.com/release/107111-UNKLESounds-Do-Androids-Dream-Of-Electric-Beats"
        }}>{`Breaking The Girl (Red Hot Chili Peppers)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=pi3FYUg78KE"
        }}>{`D.A.N.C.E. Pt. 2 (Justice)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=dQw4w9WgXcQ"
        }}>{`[REDACTED]`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=mCHUw7ACS8o"
        }}>{`Dirty Paws (Of Monsters and Men)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=hVmbWMS3W4I"
        }}>{`Put Me To Work (Big Data)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=wwvLlEtxX3o"
        }}>{`Only (Nine Inch Nails)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=N-zgdGQB4S4"
        }}>{`The Old Man's Back Again (Scott Walker)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=edo3Z8NguFo"
        }}>{`Aciiid! (Jem)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=-840keiiFDE?t=89"
        }}>{`Twilight (Electric Light Orchestra)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=wXRrSlOpnQc"
        }}>{`Crazy (Kidneythieves)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=o2dPCkh78qo"
        }}>{`Duvet `}{`[`}{`Cyberia Mix`}{`]`}{` (Boa)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=KHlWOs6Ca50"
        }}>{`End of the World Party (Medeski Martin & Wood)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=zl005muyttQ"
        }}>{`Dark Road (Annie Lennox)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=azNlYul2IoY"
        }}>{`Couleurs (M83)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=uELb1AlIzO8"
        }}>{`All the Way... (Ladytron)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=yEczZNQ6gcA"
        }}>{`Hotdog (Simian Mobile Disco)`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=PIvYkwPITFc"
        }}>{`Eye for an Eye Backwards `}{`[`}{`Remix`}{`]`}{` (UNKLE)`}</a></li>
    </ol>
    <h2>{`Mixing/Sourcing Notes`}</h2>
    <ul>
      <li parentName="ul">{`Tracks 1 and 2 are supposed to sound like the CD is skipping. Really.`}</li>
      <li parentName="ul">{`Tracks 1 and 2 are from an annoying to source soundscape mix. It's the start
of disc 2.`}</li>
      <li parentName="ul">{`Tracks 1,2 and 18 are from the same artist (UNKLE). I'm very ok with this.`}</li>
      <li parentName="ul">{`Track 3 is cut short to jump better to track 4.`}</li>
      <li parentName="ul">{`Track 4 is actually titled a Very Popular Windows XP License Key.`}</li>
      <li parentName="ul">{`Track 8 came from a Late Nite Tales: Air compilation. I just really like LNT.`}</li>
      <li parentName="ul">{`The linked video for track 10 is `}<em parentName="li">{`not`}</em>{` the source I used. You should still
watch it. Because it's awesome.`}</li>
      <li parentName="ul">{`Track 10 is cut a little short in the end.`}</li>
      <li parentName="ul">{`The entire Cyberia Mix CD where track 12 from is great...and over
`}<a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=bEHUFRRK9Sk"
        }}>{`here`}</a></li>
      <li parentName="ul">{`There were brackets intended in the titles for tracks 12 and 18, but M-Crew
stripped 'em out.`}</li>
      <li parentName="ul">{`Track 14/15/16 have reworked fades and are cut a little shorter to keep the
mix moving.`}</li>
      <li parentName="ul">{`Track 18's video is a bit creepy.`}</li>
    </ul>
    <p>{`For the selection process here, I actually started with the beginning and
ending tracks, and then filled in the gaps with things I thought worked okay.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      